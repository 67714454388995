<template>
  <div>
     <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>账号权限</div>
     <el-card class="main">
        <el-button type="primary" style="margin-left:40px;width:100px" @click="$router.push('enterprise-add')">新增员工</el-button>                          
       <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
       <el-table-column
        header-align="center"
        align="center"
         width="80px"
        label="序号"
        >
        <template slot-scope="scope">
            {{scope.$index+1}}
        </template>

      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="name"
        width="130"
        label="姓名">

      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="mobile"
        label="手机号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
         width="80"
        label="性别">
        <template slot-scope="scope">
            {{scope.row.sex == 1 ? '男': '女'}}
        </template>
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="email"
        label="邮箱">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="remark"
        label="备注">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="username"
        width="130"
        label="账号">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="roles"
        label="角色">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="statusText"
        width="160"
        label="操作">
         <template slot-scope="scope">
          <el-button  type="text" size="small" @click="assigned(scope.row)" v-if="scope.row.state == 1">分配角色</el-button>
           <el-button  type="text" size="small" @click="addOrUpdateHandle(scope.row.id)" v-if="scope.row.state == 1">编辑</el-button>
          <el-button  type="text" size="small" @click="deleteHandle(scope.row)" v-if="scope.row.state == 1">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
     <el-pagination
         background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
     <el-dialog
        title=" "
        :visible.sync="dialogVisible"
        >
      <div style="transform:translateY(-30px)">
          <!-- <img src="../../../assets/img/12.png" alt="" style="transform:translateY(-4px)"> -->
          <span style="font-size:20px;font-weight:600;color:#333;margin-left:10px;">分配角色</span> 
      </div>
      <div>
        <el-form :model="dataForm"  ref="dataForm" @keyup.enter.native="dataFormSubmit()" >
          <el-form-item label="角色" size="mini" prop="roleIdList">
            <el-checkbox-group v-model="dataForm.roleIdList">
              <el-checkbox v-for="role in roleList" :key="role.id" :label="role.id">{{ role.roleName }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item style="text-align:center;margin-top:50px">
            <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
     </el-card>
  </div>
</template>

<script>
import {list,employeeRoleIds,assign,updateRole,deleate} from  "@/api/enterprise.js";
export default {
  data() {
    return {
      dialogVisible:false,
      load:false,
      pageSize: 10,
      totalCount: 0,//数据长度
      currPage: 1,
      tableData:[],
      roleList:[],
      dataForm:{roleIdList:[]},
      userId:'',
      partyId:'',
      employeeRoleIds:'',
      dataListSelections:[]
    };
  },
  mounted(){
    this.getDataList()
  },
  methods: {
      getDataList(){
        this.load=true
         let value={
                "current": this.currPage,
                "size": this.pageSize,
                "sidx":"created_at",
                "sortord":"DESC "
            }
            list(value).then((data=>{
                 this.load=false
                    if (data.code==200) {
                    this.tableData=data.data.list
                    this.totalCount=data.data.totalCount
            }
            }))
        },
    //  每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
        //列表编辑
    addOrUpdateHandle(id){
      this.$router.push({
        name:'enterprise-add',
        query:{
          id:id
        }
      })
    },
       //提交dialog角色分配
    dataFormSubmit(){
      let params = {
        userId : this.userId,
        partyId: this.partyId,
        roleIdList : this.dataForm.roleIdList
      }
      updateRole(params).then((data) => {
        if(data.code == 200) {
          this.dialogVisible = false
          this.$message({
          message: '角色分配成功',
          type: 'success'
        });
        this.getDataList()
        } else {
          this.$message.error(data.msg);
        }
      })
    },
      //分配角色列表
    assigned(row){
      this.userId = row.userId
      this.partyId = row.partyId
      this.employeeRoleIds = row.id
      this.dialogVisible = true
      let params = {
        employeeId:row.id
      }
      assign().then((data) => {
        this.roleList = data && data.code === 200 ? data.data : []
      })
      employeeRoleIds(params).then((data) => {
        if(data.code == 200) {
          this.dataForm.roleIdList = data.data
        } else {
          this.$message.error(data.msg);
        }
      })
    },
     //删除
    deleteHandle(row){
        var ids = row.id ? [row.id] : this.dataListSelections.map(item => {
          return item.id         
        })
    this.$confirm(`确定对[${row.name}]进行删除操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleate(ids).then((data) => {
            if (data && data.code === 200) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }).catch(() => {})
    },

    }
};
</script>

<style  scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.main{
    margin-top: 10px;
}
/deep/.el-dialog__headerbtn .el-dialog__close{
  font-size: 26px !important;
}
</style>


